<template>
    <div>
        employee ข้างในมี
        <br><br>
        รหัสพนักงาน<br>	ชื่อพนักงาน<br>	จัดการ<br>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>